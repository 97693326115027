import React from "react"
import { Formik, useField, Form } from "formik"
import { TextField } from "./TextField"
import { TextArea } from "./TextArea"
import * as Yup from "yup"
import { BalField } from "./BalField"
import {
  SendText,
  SanalHuseltText,
  EmailText,
  SanalHuseltIlgeehText,
  PhoneText,
} from "../graphql/QueryById"

const SendMessage = ({ handleSubmit, loading }) => {
  // const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}$/
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validate = Yup.object({
    // Subject: Yup.string()
    //   .max(40, "Must be less than 40 characters")
    //   .required("Required"),
    Name: Yup.string(),
    Text: Yup.string()
      .max(450, "Уучлаарай, оруулах мэдээллийн тоо хэтэрсэн байна.")
      .required("Та санал хүсэлтээ энэ талбарт бичээрэй"),
    Email: Yup.string()
      .email("Имэйл хаяг буруу байна")
      .required(
        "Уучлаарай, та имэйл хаягаа оруулна уу. Учир нь бид таны имэйл хаягын дагуу хариу өгөх болно."
      ),
    Phone: Yup.string()
      .matches(phoneRegExp, "Таны оруулсан утас буруу байна.")
      .required("Уучлаарай, та утасны дугаараа оруулна уу."),
  })

  return (
    <Formik
      initialValues={{
        // Subject: "",
        Name: "",
        Email: "",
        Text: "",
        Phone: "",
      }}
      validationSchema={validate}
      // onSubmit={values => {
      //   setMessage(values)
      // }}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {formik => {
        return (
          <div className="flex flex-col py-4 my-2">
            <h1 className="flex-grow py-4 text-lg font-semibold text-center text-gray-600 uppercase md:mb-4">
              {/* "Санал хүсэлт илгээх" */}
              {SanalHuseltIlgeehText()}
            </h1>
            <Form>
              {/* <TextField label="Сэдэв" name="Subject" type="text" /> */}
              {/* <TextField label="Нэр" name="Name" type="text" /> */}
              <BalField label="Нэр" name="Name" type="text" />
              <div className="grid md:space-x-4 md:grid-cols-2 ">
                <TextField
                  label={EmailText()}
                  name="Email"
                  type="email"
                  placeholder="contact@example.com"
                />
                <TextField
                  label={PhoneText()}
                  // label="Утас"
                  name="Phone"
                  type="number"
                  placeholder="99999999"
                  className="appearance-none"
                />
              </div>
              <div className="mt-8">
                <TextArea
                  label={SanalHuseltText()}
                  // label="Санал хүсэлт"
                  name="Text"
                  type="textarea"
                />
              </div>

              <div className="flex justify-center md:justify-end">
                <button
                  type="submit"
                  className={`btn btn-secondary hover:bg-white hover:shadow-lg focus:bg-gray-200 focus:ring-0 w-full md:w-40 hover:text-gray-900 text-sm ${
                    loading
                      ? "text-gray-100 border-gray-50 pointer-events-none"
                      : "border-gray-300 text-gray-900"
                  }`}
                >
                  {SendText()}
                </button>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default SendMessage
