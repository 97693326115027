import React, { useState, useEffect } from "react"
import base from "./Airtable"
import SendMessage from "./SendMessage"
import DOMPurify from "dompurify"
import { ThanksForMessagingText } from "../graphql/QueryById"

const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [formHide, setFormHide] = useState(false)

  const addRecord = async data => {
    setLoading(true)

    await base("Message from Customers")
      .create([
        {
          fields: data,
        },
      ])
      .catch(err => console.log(err))

    setLoading(false)
  }

  const handleSubmit = values => {
    const pureObject = {
      Email: DOMPurify.sanitize(values.Email, {
        ALLOWED_TAGS: [],
        ALLOWED_ATTR: [],
      }),
      Text: DOMPurify.sanitize(values.Text, {
        ALLOWED_TAGS: [],
        ALLOWED_ATTR: [],
      }),
      Phone: DOMPurify.sanitize(values.Phone, {
        ALLOWED_TAGS: [],
        ALLOWED_ATTR: [],
      }),
    }
    if (!values.Name) {
      addRecord(pureObject)
    }
    setFormHide(true)
  }

  const [thansMsg, setThanksMsg] = useState(null)

  return (
    <div className="w-full px-4">
      <div className={`${formHide ? "hidden" : ""}`}>
        <SendMessage handleSubmit={handleSubmit} loading={loading} />
      </div>

      <div
        className={`flex items-center justify-center h-40 px-2 text-sm font-semibold text-center md:h-120 md:text-base ${
          formHide ? "" : "hidden"
        }`}
      >
        <h2>{ThanksForMessagingText()}</h2>
      </div>
    </div>
  )
}

export default ContactForm
