import React, { useState, useRef } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { OntslogCategory } from "../graphql/CreatePageQuery"
import { AllRaisedFloors } from "../graphql/AllRaisedFloorQuery"
import { RaisedFloorDesc, RfType } from "../graphql/RaisedFloorSmallQueries"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ZoomControl,
} from "react-leaflet"
import { Helmet } from "react-helmet"
import * as leafletCSS from "../styles/leaflet.css"
import { AllContact } from "../graphql/ContactQuery"
import icon from "../images/store.png"
import iconMainStore from "../images/leaf-red.png"
import iconBranchStore from "../images/leaf-green.png"
import iconMainStoreCropped from "../images/leaf-red-crop.png"
import iconBranchStoreCropped from "../images/leaf-green-crop.png"
import iconShadow from "../images/leaf-shadow.png"
import AnchorLink from "react-anchor-link-smooth-scroll"
// import L from "leaflet"
import ContactForm from "../components/ContactForm"
import SignUpForm from "../components/SignUpForm"
import ContactRigthSideInfo from "../components/ContactRigthSideInfo"
import ButtomSocialMenu from "../components/ButtomSocialMenu"
import {
  // TextMainBranchName,
  // TextSalbarBranchName,
} from "../components/MenuItems"
import { ContactText } from "../graphql/QueryById"

const Contact = () => {
  const contacts = AllContact().sort((a, b) => a.branch - b.branch)
  const controlRef = useRef(null)

  const imageMainStore = (
    <img src={iconMainStoreCropped} alt="Төв дэлгүүр" className="w-4 h-4" />
  )
  // const imageBranchStore = (
  //   <img
  //     src={iconBranchStoreCropped}
  //     alt="Салбар дэлгүүр"
  //     className="w-4 h-4"
  //   />
  // )

  const mainBranch = AllContact().filter(item => item.branch === "1")[0]

  const [value, setValue] = useState(null)

  const myIcon =
    typeof window !== "undefined" && window
      ? [
          L.icon({
            iconUrl: iconMainStore,
            iconSize: [38, 95],
            iconAnchor: [22, 94],
            popupAnchor: [-3, -76],
            shadowUrl: iconShadow,
            shadowSize: [68, 95],
            shadowAnchor: [8, 94],
          }),
          // L.icon({
          //   iconUrl: iconBranchStore,
          //   iconSize: [38, 95],
          //   iconAnchor: [22, 94],
          //   popupAnchor: [-3, -76],
          //   shadowUrl: iconShadow,
          //   shadowSize: [68, 95],
          //   shadowAnchor: [8, 94],
          // }),
        ]
      : undefined

  const marker =
    typeof window !== "undefined" && window 
      ? contacts.map((store, index) => (
          <Marker
            key={store.id}
            icon={myIcon[index]}
            position={[store.long, store.lat]}
            onMouseEnter={() => {
              controlRef.current.L.openPopup()
            }}
            onMouseLeave={() => {
              controlRef.current.closePopup()
            }}
            ref={controlRef}
          >
            <Popup>
              <div className="relative">
                <div className="h-60 overflow-hidden">{store.outsideImage[0]}</div>
                <div className="text-base font-bold text-center uppercase">
                  {store.name}
                </div>
                <div className="mt-1 text-xs md:text-sm">{store.address}</div>
                {store.phone && (
                  <div className="mt-2 text-sm">
                    Утас: <span className="font-semibold">{store.phone}</span>
                  </div>
                )}
                {/* <div
                  className="inline-block px-2 py-1 mt-2 bg-gray-100 cursor-pointer"
                  onClick={() => {
                    setValue(index)
                    controlRef.current.closePopup()
                  }}
                >
                  <AnchorLink href="#moreInfo">
                    Дэлгэрэнгүй мэдээлэл авах
                  </AnchorLink>
                </div> */}
              </div>
            </Popup>
          </Marker>
        ))
      : undefined

  return (
    <Layout svgHide>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossorigin=""
        />
      </Helmet>
      <SEO title={ContactText()} description='Nippon standart-тай холбоо барих' />
      <div className="">
        <div className="relative h-120">
          {typeof window !== "undefined" && window && (
            <MapContainer
              center={[47.91418846371046, 106.8914939116431]}
              zoom={23}
              zoomControl={false}
            >
              <ZoomControl position="bottomright" />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {marker}
            </MapContainer>
          )}
          <div className="absolute inset-x-0 bottom-0 flex justify-end h-8 px-2 py-2 -mb-4 space-x-3 bg-white md:px-12">
            {/* <div className="flex items-center space-x-1">
              {imageMainStore}
              <span className="text-xs text-gray-900">
                - {TextMainBranchName()}
              </span>
            </div> */}
            {/* <div className="flex items-center space-x-1">
              {imageBranchStore}
              <span className="text-xs text-gray-900">
                - {TextSalbarBranchName()}
              </span>
            </div> */}
          </div>
        </div>

        <div className="relative z-20 grid justify-between max-w-screen-xl grid-cols-1 mt-16 md:gap-10 md:grid-cols-2 sm:mx-auto">
          <div>
            <ContactRigthSideInfo />
          </div>
          {/* <div className="order-first w-full md:mt-20 md:order-last"> */}
          <div className="order-first w-full md:order-last">
            <div className="my-10 bg-gray-100 rounded-lg shadow-lg md:px-10 md:py-5">
              <ContactForm />
            </div>
          </div>
        </div>

        <div className="mt-10 ">
          <ButtomSocialMenu />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
