import React, { useState, useEffect } from "react"
import { AllContact } from "../graphql/ContactQuery"
import SingleProductSwiper from "./SingleProductSwiper"
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFacebook,
  // FaInstagram,
} from "react-icons/fa"
import { HiOutlineMailOpen } from "react-icons/hi"

const ContactRigthSideInfo = () => {
  const [value, setValue] = useState(0)

  const arrangedContact = AllContact().sort((a, b) => a.branch - b.branch)

  const storeName = arrangedContact.map((item, index) => {
    const { id, name } = item ?? undefined
    return (
      <div key={id}>
        <h2
          className={`px-4 py-2 font-bold cursor-pointer uppercase sm:tracking-widest text-xs md:text-sm text-center ${
            index === value
              ? "bg-gray-200 text-black rounded-full shadow-md"
              : "text-gray-600"
          }`}
          onClick={() => setValue(index)}
        >
          {name}
        </h2>
      </div>
    )
  })

  const storeInfo = arrangedContact.map((item, index) => {
    const [zoom, setZoom] = useState(false)
    const [zoomImage, setZoomImage] = useState(null)
    const {
      id,
      branch,
      name,
      address,
      outsideImage,
      insideImage,
      email,
      phone,
      facebook,
      post,
    } = item ?? undefined
    const allStoreImages = [...outsideImage, ...insideImage]

    return (
      <div key={id}>
        <div className="relative pt-1">
          <SingleProductSwiper
            images={allStoreImages}
            setZoomImage={setZoomImage}
            setZoom={setZoom}
            zoom={zoom}
            noZoom
          />
        </div>

        <div className="items-center mx-2 space-y-4 ">
          {address && (
            <div className="grid grid-cols-5">
              <div className="flex items-center justify-center">
                <FaMapMarkerAlt className="w-6 h-6 text-gray-500" />
              </div>
              <div className="col-span-4 text-sm text-gray-800 md:text-base">
                {address}
              </div>
            </div>
          )}
          {phone && (
            <a href={`tel:${phone}`} className="block ">
              <div className="items-center">
                <div className="grid grid-cols-5 ">
                  <div className="flex items-center justify-center">
                    <FaPhoneAlt className="w-5 h-5 text-gray-500" />
                  </div>
                  <div className="col-span-4">
                    <div className="text-sm font-bold tracking-widest md:text-base">
                      {phone}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          )}
          {email && (
            <div className="grid items-center grid-cols-5">
              <div className="flex items-center justify-center">
                <HiOutlineMailOpen className="w-6 h-6 text-gray-500" />
              </div>
              <div className="col-span-4 text-sm font-bold tracking-widest md:text-base">
                {email}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  })

  return (
    <div>
      <div className="mx-2 mt-10">
        <div className="mt-10">{storeInfo[value]}</div>
      </div>
    </div>
  )
}

export default ContactRigthSideInfo
