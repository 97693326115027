import React from "react"
import { useField, ErrorMessage } from "formik"

export const BalField = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  // console.log({ field, meta })
  return (
    <div className="hidden my-2">
      <div>
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">
          <input
            type="text"
            autoComplete="off"
            {...field}
            {...props}
            className="w-full border-gray-300 rounded-lg shadow-sm"
          />
        </div>
      </div>
      {/* <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        type="text"
        className="w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        autoComplete="off"
      /> */}
    </div>
  )
}
