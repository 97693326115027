import React from "react"
import { useField, ErrorMessage } from "formik"

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  // console.log({ field, meta })
  return (
    <div className="my-2">
      <div>
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="mt-1">
          <input
            type="text"
            autoComplete="off"
            {...field}
            {...props}
            className={`w-full border-gray-300 rounded-lg shadow-sm placeholder-gray-400 ${
              meta.touched && meta.error
                ? "text-red-500 focus:border-red-500 focus:ring-red-500 border-red-500 border"
                : "focus:border-indigo-500 focus:ring-indigo-500"
            }`}
          />
          {/* <div className="text-red-600"> */}
          <ErrorMessage
            component="div"
            name={field.name}
            className="mt-1 text-xs text-red-500"
          />
          {/* </div> */}
        </div>
      </div>
      {/* <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <input
        type="text"
        className="w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        autoComplete="off"
      /> */}
    </div>
  )
}
